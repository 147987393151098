<script setup>
import CheckBoxInput from "@/components/common/CheckInput.vue"
import TextField from "@/components/common/TextField.vue"
import useVuelidate from "@vuelidate/core"
import { required } from "@vuelidate/validators"
import { ref } from "vue"


// const dataForm = ref({})

const handlerValidate = useVuelidate(
  {
    title: {
      required,
    },
  }
//   dataForm.value
);

const structure = ref([
  {
    id: 1,
    name: "Necesitas ayuda con el guion",
  },
  {
    id: 2,
    name: "Necesitas ayida con el grabado",
  },
  {
    id: 3,
    name: "Necesitas ayuda con la edicion",
  },
  
]);

const tools = ref([
  {
    id: 1,
    name: "Estrategia de Marketing",
  },
  {
    id: 2,
    name: "Manejo de trafico",
  },
  {
    id: 3,
    name: "⁠Elaboracion de ads",
  },
  {
    id: 4,
    name: "⁠Elaboracion de video",
  },
  {
    id: 5,
    name: "Edicion de videos",
  },
  {
    id: 6,
    name: "Equipo de ventas (closers y seter)",
  },
  {
    id: 7,
    name: "⁠Otra cosa",
  },
  
]);


const sendForm = () => {
    handlerValidate.value.$touch();
}
</script>
<template>
  <section>
    <p class="title-text fw-bold" v-text="'Cuestionario de asesoramiento:'" />
    <p
      class="w-50 mb-4"
      v-text="
        'En Skilpering queremos der tus aliados, para lo cusl tneemos una abanico de opciones para ayudarte en tu labor como Skilper, para esto necesitamos q llenes el sgiuiento fornulario q nos dejara enteder cuales son tus necesidades:'
      "
    />

    <div class="form-group w-50">
      <div class="d-md-flex gap-4 mb-2">
        <TextField
          v-model="saleCourse"
          :label="'¿Has vendido anteriormente cursos?'"
          class="mb-2"
        />
        <TextField
          v-model="saleCourse"
          :label="'¿Que tipo de cursos has vendido?'"
          class="mb-2"
        />
      </div>
      <div class="d-md-flex gap-4 mb-2">
        <TextField
          v-model="saleCourse"
          :label="'¿Grabas tu mismo tu cursos?'"
          class="mb-2"
        />
        <TextField
          v-model="saleCourse"
          :label="'¿Haces tus propias campañas de publicidad?'"
          class="mb-2"
        />
      </div>
      <div class="d-md-flex gap-4 mb-2">
        <TextField
          v-model="saleCourse"
          :label="'¿Generas tu propio contenido en redes?'"
          class="mb-2"
        />
        <TextField
          v-model="saleCourse"
          :label="'¿Necesitas que te apoyemos con Sters o closers?'"
          class="mb-4"
        />
      </div>
      <div class="mb-4">
        <p class="textField mb-2" v-text="'Necesitas ayuda en la estructuracion de tu curso, para esto:'"></p>
        <CheckBoxInput
          class="d-md-flex gap-4 mb-2"
          v-for="(item, index) in structure"
          :key="item.id"
          :value="item.id"
          :for="`${index}`"
          :text="item.name"
         
        />
      </div>

      <div class="mb-4">
        <p class="textField mb-2" v-text="'Necesitas ayuda en la estructuracion de tu curso, para esto:'"></p>
        <CheckBoxInput
          class="d-md-flex gap-4 mb-2"
          v-for="(item, index) in tools"
          :key="item.id"
          :value="item.id"
          :for="`${index}`"
          :text="item.name"
         
        />
      </div>
      <button  class="btn btn-primary w-50 text-center m-auto d-flex justify-content-center" @click="sendForm">Enviar</button>

    </div>
  </section>
</template>
<style scoped>
.textField{
  font-weight: 500;
}
.w-30 {
  width: 50%;
}
</style>